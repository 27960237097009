import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import { ValidationTextFieldInput } from '@fingo/lib/components/inputs';
import { useInputRut } from '@fingo/lib/hooks';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { useMutation } from '@apollo/client';
import { UPDATE_SELECTED_COMPANY_BY_RUT } from '@fingo/lib/graphql/user/mutations';

const TEXT_WIDTH = 250;
const MARGIN = 3;

const TellUsYourRut = () => {
  const { nationalIdentifier: rut, updateNationalIdentifier: updateRut, isValid } = useInputRut();
  const history = useHistory();
  const [updateSelectedCompanyByRut, { loading }] = useMutation(UPDATE_SELECTED_COMPANY_BY_RUT, {
    variables: {
      companyRut: rut.raw,
    },
    onCompleted: () => {
      history.push('/app/home');
    },
  });

  const onChangeInput = useCallback((event) => {
    const { value } = event.target;
    updateRut(value);
  }, [updateRut]);

  return (
    <Box
      sx={{
        alignSelf: 'center',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        margin: 'auto',
      }}
    >
      <Stack
        sx={{
          width: 450,
          maxHeight: 450,
          p: 8,
          mr: 'auto',
          ml: 'auto',
          alignSelf: 'center',
          backgroundColor: 'background.paper',
          borderRadius: 4,
        }}
        elevation={0}
      >
        <Typography variant="medium_3" sx={{ mb: 3 }}>
          Ingresa el RUT de tu empresa
        </Typography>
        <Grid container alignItems="center" direction="column">
          <ValidationTextFieldInput
            name="rut"
            type="text"
            validationtype="rut"
            label="RUT Empresa"
            variant="standard"
            value={rut.formatted}
            onChange={onChangeInput}
            sx={{ width: TEXT_WIDTH, mt: MARGIN }}
            InputLabelProps={{ sx: { typography: 'small_2' } }}
            InputProps={{ sx: { typography: 'medium_1' } }}
          />
          <LoadingButton
            variant="contained"
            type="submit"
            loading={loading}
            disabled={!rut.raw || !isValid}
            sx={{
              mt: MARGIN,
              width: 200,
            }}
            onClick={updateSelectedCompanyByRut}
          >
            Continuar
          </LoadingButton>
        </Grid>
      </Stack>
    </Box>
  );
};

export default TellUsYourRut;
